import React from 'react';
import Layout from '../components/layout-en'
import Head from '../components/head'
import '../styles/know-your-soil.css'
//import scrollTo from 'gatsby-plugin-smoothscroll';
import Carousel from 'react-elastic-carousel'
import { OutboundLink } from "gatsby-plugin-google-analytics"
import {graphql} from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from 'gatsby'

import {Row, Col} from 'react-bootstrap'
import GooglePlay from '../images/google-store.png'

import rightArrow1 from '../images/right-arrow.png'
import TaniaBusy from '../images/tania-busy-2.png';
import KalkulatorPupuk from '../images/kalkulator-pupuk.png'
import ChartPemasukan from '../images/chart-pemasukan.png';

import ChartPengeluaran from '../images/chart-pengeluaran.png';
import DrTaniaLogo from "../images/drtania-logo.png";


export const query = graphql`{
  GooglePlayImg: file(relativePath: {eq: "google-store.png"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  DrTaniaImg: file(relativePath: {eq: "dr-tania.png"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  KysImg: file(relativePath: {eq: "kys-picture.png"}) {
    childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
  }
  KysAccurateImg: file(relativePath: {eq: "kys-accurate.png"}) {
    childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
  }
  KysAffordableImg: file(relativePath: {eq: "kys-affordable.png"}) {
    childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
  }
  KysEasyImg: file(relativePath: {eq: "kys-easy.png"}) {
    childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
  }

  AppMockupImg: file(relativePath: {eq: "app-mockup.png"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  Identifikasi: file(relativePath: {eq: "identifikasi.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  Tanya: file(relativePath: {eq: "tanya.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  Budidaya: file(relativePath: {eq: "budidaya.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  TaniaBusy: file(relativePath: {eq: "tania-busy-2.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  KalkulatorPupuk: file(relativePath: {eq: "kalkulator-pupuk.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  ChartPemasukan: file(relativePath: {eq: "chart-pemasukan.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  ChartPengeluaran: file(relativePath: {eq: "chart-pengeluaran.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  Kebun: file(relativePath: {eq: "kebun.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  DrTaniaLogo: file(relativePath: {eq: "drtania-logo.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  FarmerLogo: file(relativePath: {eq: "farmer-logo.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  CityLogo: file(relativePath: {eq: "city-logo.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  FieldLogo: file(relativePath: {eq: "field-logo.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
}
`
//http://tania-user-service.neurafarm-services.com/website/v2/countusers
//https://swapi.co/api/people


class DokterTania extends React.Component {
    constructor(props){
        super(props);
        this.listener = null;
        this.state = {
            status: "section1",
            jmlFarmer : 3718
        };
    }
    
    componentDidMount(){

        fetch('https://tania-user-service.azurewebsites.net/website/v2/countusers').then(response => {
            return response.json()
        }).then(json => {
            this.setState({jmlFarmer : json.payload.num_of_users});
        })
        
        this.listener = document.addEventListener("scroll", e => {
            var scrolled = document.scrollingElement.scrollTop;
            if (scrolled >= 400 && scrolled <= 1000){
                if (this.state.status !== "section2"){
                    this.setState({status : "section2"});
                }
            } else if (scrolled > 1000 && scrolled <= 1500){
                if (this.state.status !== "section3"){
                    this.setState({status : "section3"});
                }
            } else if (scrolled > 1500 && scrolled <= 2000){
                if (this.state.status !== "section4"){
                    this.setState({status : "section4"});
                }
            } else if (scrolled > 2000){
                if (this.state.status !== "section5"){
                    this.setState({status : "section5"});
                }
            } else {
                if (this.state.status !== "section1"){
                    this.setState({status : "section1"});
                }
            }
        });

        
    }

    componentDidUpdate(){
        document.removeEventListener("scroll", this.listener);
    }

    render(){
        console.log(this.state.jmlFarmer)
        return (
            <Layout>
                <Head title = "Dokter-Tania"/>
                <div id = "dt-main-container">

                    <div id = 'dt-section-container'>

                        <div id = "dt-section-1">

                            <div className = "additional-circle circle-section-1-right" />

                            <Row>
                                <div className = "additional-circle circle-section-1-left" />
                                <Col md={6} id = "dt-section-1-col-1">
                                    <div id = "dt-sec-1-col-1-img-container">
                                        <div id = "kys-img">

                                            <GatsbyImage
                                                image={this.props.data.KysImg.childImageSharp.gatsbyImageData}
                                                alt = "KYS-Img" />
                                            
                                        </div>
                                                                     
                                        <div className = "additional-circle circle-section-1-middle" />
                                    </div>
                                </Col>


                                <Col md={5} id = "dt-section-1-col-2">
        
                                    <div id = "dt-section-1-primary-title"> 
                                        Kenali Tanahmu
                                    </div>  

                                    <div id = "secondary-title"> 
                                        <div>Kenali Tanahmu, Maksimalkan Panenmu</div>                      
                                        <div>Kenali Tanahmu, Temani Kegiatan Bertanimu</div>
                                        <div>Kenali Tanahmu, Panen Berbukit, Biaya Makin Irit</div>
                                    </div>

                                    <div id = "content">
                                        <p>
                                            Mau hasil panen berbukit dengan biaya yang irit? Kenali tanahmu dengan Jasa Analisis Tanah dari Neurafarm sekarang! 
                                        </p>

                                        <p>
                                            Bertani dengan lebih efektif dan efisien, kurang biaya dan maksimalkan hasil lahan.
                                        </p>      

                                        <div id = "read-more">
                                            <Link to = "/what-we-do" id = "read-more-link">
                                                <span id = "read-more-link-span">Kenali Tanah Sekarang</span> 
                                                <img id = "read-more-wwd" src = {rightArrow1} alt = "right-arrow" width = "18px"/>
                                            </Link>
                                        </div>    


                                    </div>
                                </Col>

                            </Row>
                        </div>

                        <div id = "dt-section-2">
                                
                            <div id = "dt-section-2-header-container">
                                
                                <div className = "additional-circle circle-section-2-right" />

                                <div id = "section-2-title">
                                    Kenapa harus #KenaliTanahmu?
                                </div>

                                <div className = "additional-circle circle-section-2-left" />
                            </div>

                            <Row className="justify-content-md-center section-2-body">
                                    <Col md>
                                        <div className="dt-section-2-content">
                                            <div className = "dt-section-2-body-img">
                                                <GatsbyImage
                                                    image={this.props.data.KysAffordableImg.childImageSharp.gatsbyImageData}
                                                    alt = "affordable-img" />
                                            </div>
                                            <div className = "dt-section-2-body-title">Murah</div>
                                            <p>
                                            Dapatkan informasi dan rekomendasi kualitas tinggi dengan harga terjangkau 
                                            </p>
                                        </div>
                                    </Col>

                                    <Col md>
                                        <div className="dt-section-2-content">
                                            <div className = "dt-section-2-body-img">
                                                <GatsbyImage
                                                    image={this.props.data.KysEasyImg.childImageSharp.gatsbyImageData}
                                                    alt = "budidaya-img" />
                                            </div>

                                            <div className = "dt-section-2-body-title">Mudah</div>
                                            <p>
                                                Hanya perlu kirim sampel tanah dan dapatkan hasil analisis dengan cepat
                                            </p>
                                        </div>
                                    </Col>

                                    <Col md>
                                        <div className="dt-section-2-content">
                                            <div className = "dt-section-2-body-img">
                                                <GatsbyImage
                                                    image={this.props.data.KysAccurateImg.childImageSharp.gatsbyImageData}
                                                    alt = "accurate-img" />
                                            </div>

                                            <div className = "dt-section-2-body-title">Akurat</div>
                                            <p>
                                            Metode analisis teruji resmi oleh Balai Penelitian Tanah sehingga hasil akurat
                                            </p>
                                        </div>
                                    </Col>




                                
                            </Row>

                            <div className = "additional-circle circle-section-2-middle" />
                        </div>

                        <div id = "dt-section-3">
                                
                                
                            <div className = "additional-circle circle-section-3-right" />
                        
                            <div className = "additional-circle circle-section-3-left" />

                            <div id = "dt-section-3-content-container">
                                <img className = "section-3-tania-busy-img" src = {TaniaBusy} alt = "tania-busy-img"/>
                                <div id = "dt-section-3-content">
                                    <div id = "dt-section-3-content-header">
                                        <img className = "calculator-pupuk-img" src = {KalkulatorPupuk} alt = "kalkulator-pupuk-img" width = "40px"/>
                                        <div id = "dt-section-3-content-title">Kalkulator Pupuk</div>
                                    </div>
                                    <div id = "dt-section-3-content-paragraph">
                                        Kombinasikan dengan fitur Kalkulator pupuk di Aplikasi Dokter Tania untuk hasil yang lebih maksimal. Bertani jadi lebih menguntungkan.
                                    </div>
                                </div>

                                <div id = "dt-section-3-chart-container">
                                    <img className = "chart" src = {ChartPemasukan} alt = "chart-pemasukan-img"/>
                                    <img className = "chart" src = {ChartPengeluaran} alt = "chart-pengeluaran-img"/>
                                </div>
                            </div>

                            <div className = "additional-circle circle-section-3-middle" />
                        </div>

                    

                        <div id = "dt-section-5">
                            <div id = "dt-section-5-title">Apa Kata Petani tentang #KenaliTanahmu?</div>

                            <div className = "dt-section-5-carousel">                            
                                <Carousel itemsToShow={2} disableArrowsOnEnd={false}>
                                    <div className = "s">
                                        <div className = "carousel-item-header">
                                            “Setelah menggunakan jasa ini, penggunaan pupuk saya menurun sekitar 50% dan panen kemarin meningkat sekitar 30%!”
                                        </div>

                                        <div className = "carousel-item-footer">
                                            <div className = "profil-img"></div>
                                            <div className = "profil">
                                                <div className = "profil-name">Ismail</div>
                                                <div className = "profil-profession">Petani Jagung dan Cabai</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className = "s">
                                        <div className = "carousel-item-header">
                                            “Aplikasi pupuk Urea, KCL, dan SP36 menurun. Cukup puas dengan hasilnya!”
                                        </div>

                                        <div className = "carousel-item-footer">
                                            <div className = "profil-img"></div>
                                            <div className = "profil">
                                                <div className = "profil-name">Syuja'li</div>
                                                <div className = "profil-profession">Petani Jagung dan Cabai di Tanjung Balai</div>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel>
                            </div>

                            <div id = "dt-section-5-comment-container">
                                <div className = "comment-container-box-1">
                                    <div className = "s">
                                        <div className = "carousel-item-header">
                                            “Wow, this application is very helpful. I really like the "ask experts" features. 
                                            Since using this feature, I have become even more aware of the 
                                            insecticide because it was explained by a tania doctor”
                                        </div>

                                        <div className = "carousel-item-footer">
                                            <div className = "profil-img"></div>
                                            <div className = "profil">
                                                <div className = "profil-name">Mudah Kuprianto</div>
                                                <div className = "profil-profession">Conventional Farmers</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className = "s">
                                        <div className = "carousel-item-header">
                                            “I love this app. I like the disease identification feature the most, 
                                            it really helps me who works in agriculture when I'm lazy to googling”
                                        </div>

                                        <div className = "carousel-item-footer">
                                            <div className = "profil-img"></div>
                                            <div className = "profil">
                                                <div className = "profil-name">Hikmaya</div>
                                                <div className = "profil-profession">Agricultural Workers</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className = "comment-container-box-2">

                                    <div className = "s">
                                        <div className = "carousel-item-header">
                                            “I really like this app, especially the Q & A and agriculture articles. 
                                            Very helpful for me who still new about agriculture but want to start trying. Hope it continues to grow”
                                        </div>

                                        <div className = "carousel-item-footer">
                                            <div className = "profil-img"></div>
                                            <div className = "profil">
                                                <div className = "profil-name">Fira</div>
                                                <div className = "profil-profession">Agricultural Hobbyist</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className = "s">
                                        
                                        <div className = "carousel-item-header">
                                            “This application really helps me,  I like the "ask expert" feature the most. 
                                            For me who like to grow vegetables at home, now I have a place to ask questions 
                                            about agriculture.”
                                        </div>

                                        <div className = "carousel-item-footer">
                                            <div className = "profil-img"></div>
                                            <div className = "profil">
                                                <div className = "profil-name">Fahri</div>
                                                <div className = "profil-profession">Agricultural Hobbyist</div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    
                                </div>

                            </div>


                                
                        </div>

                        <div id = "dt-section-6">
                                
                            <div className = "additional-circle circle-section-6-right" />
                            <div className = "additional-circle circle-section-6-left" />
                            <div id = "dt-section-6-main-container">
                                <img className = "dr-tania-logo" src = {DrTaniaLogo} alt = "dr-tania-logo"/>
                                <div className = "additional-circle circle-section-6-middle" />
                                <h4>Siap tingkatkan hasil lahan?</h4>
                                <OutboundLink href = "https://neurafarm.page.link/kys-landing" id = "test-now">
                                    <span id = "test-now-link-span">Kenali Tanah Sekarang</span> 
                                    <img id = "test-now-wwd" src = {rightArrow1} alt = "right-arrow" width = "18px"/>
                                </OutboundLink>
                            </div>



                            <div className = "additional-circle circle-section-6-bottom" />
                            
                        </div>
                        

                    </div>

                    
                </div>
            </Layout>
        );
    }    
}

export default DokterTania;